import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

const SuiviAnneau = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Suivi Anneau" />
        <Typography
          variant="h4"
          color="inherit"
          style={{ margin: "30px 0px 20px" }}
        >
          Anneau gastrique
        </Typography>
        <Typography variant="body1">
          <strong>Suivi en médecine générale :</strong> 1 à 2 fois par an, à vie
          (tous les 3 mois la première année)
        </Typography>
        <Typography variant="body1" style={{ marginTop: 8 }}>
          <strong>Suivi spécialisé :</strong> le suivi après chirurgie
          bariatrique impose au minimum une consultation chirurgicale et/ou
          nutritionnelle par an, à vie.
        </Typography>
        <Typography
          variant="h5"
          color="inherit"
          style={{ margin: "30px 0px 20px" }}
        >
          Interrogatoire
        </Typography>
        <Typography variant="body1" component={"span"}>
          <ul>
            <li>Evaluation de l'alimentation et <a href="/generalities#alimentation">rappel des conseils diététiques</a></li>
            <li>
              <a href="/suivi#evaluation">Evaluation de l’activité physique</a>
            </li>
            <li>Symptômes évocateurs de carences</li>
            <li>Signes fonctionnels digestifs</li>
            <li>Recherche d’un reflux gastro-œsophagien</li>
            <li>
              Recherche et rappel des{" "}
              <a href="/suivi#signes">
                signes cliniques qui imposent de consulter en urgence
              </a>
            </li>
            <li>
              <a href="/suivi#sociale">Evaluation psychologique et sociale</a>
            </li>
            <li>IMC (évaluation de la perte de poids et de sa cinétique)</li>
          </ul>
        </Typography>
        <Typography
          variant="h5"
          color="inherit"
          style={{ margin: "30px 0px 20px" }}
        >
          Examen clinique
        </Typography>
        <Typography variant="body1" component={"span"}>
          <ul>
            <li>Constantes</li>
            <li>Auscultation cardio-pulmonaire</li>
            <li>Palpation et auscultation abdominale</li>
            <li>
              Recherche d’une atteinte neurologique évocatrice de carences
            </li>
          </ul>
        </Typography>
        <Typography
          variant="h5"
          color="inherit"
          style={{ margin: "30px 0px 20px" }}
        >
          Bilan biologique
        </Typography>
        <Typography variant="body1" component={"span"}>
          <div>1 à 2 fois par an (+ à 3 et 6 mois après l’intervention).</div>
          <div>
            Hémoglobine, albumine et préalbumine, ferritine et coefficient de
            saturation de la transferrine, vitamine D, calcémie, PTH, vitamines
            A, B1, B9, B12, zinc et sélénium. Le reste du bilan est orienté par
            la clinique.
          </div>
        </Typography>
        <Typography variant="body1" style={{marginTop: 12}}>
          <a href="/ordonnance_type.pdf" target="_blank">Voir ordonnance type</a>
        </Typography>
        <Typography
          variant="h5"
          color="inherit"
          style={{ margin: "30px 0px 20px" }}
        >
          Surveillance des comorbidités et adaptation des traitements
        </Typography>
        <Typography variant="body1" component={"span"}>
          Si nécessaire.<br/>
          Voir{" "}<a href="/comorbidites">adaptation des thérapeutiques</a>
        </Typography>
        <Typography
          variant="h5"
          color="inherit"
          style={{ margin: "30px 0px 20px" }}
        >
          Supplémentations
        </Typography>
        <Typography variant="body1" component={"span"}>
          Aucune systématique.<br/>
          Voir{" "}<a href="/supplementations">supplémentations : en pratique</a>
        </Typography>
        <div style={{ width: "100%", marginTop: 40, textAlign: "center" }}>
          <Button href="/suivi">Revenir au Suivi</Button>
        </div>
      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default SuiviAnneau
